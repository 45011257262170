import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Avatar,
} from '@mui/material';
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person'; // Replace with your desired icon

import EventNoteIcon from '@mui/icons-material/EventNote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // Import the logistic icon
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Import the expense/revenue icon






import logoImage from '../assets/logo2.png';

const Navbar = ({ userProfilePicture, handleLogout }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(false);
    const [userType, setUserType] = useState(false);
    const [userSpecialRole, setuserSpecialRole] = useState(false);
    const [userName, setUserName] = useState(false);
    const [userID, setUserID] = useState(false);
    const [userID2, setUserID2] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        const userType = localStorage.getItem('userType');
        const userSpecialRole = localStorage.getItem('userSpecialRole');
        const userName = localStorage.getItem('userName');
        const userID = localStorage.getItem('userID');
        const userID2 = localStorage.getItem('userID2');
        setUserType(userType)
        setUserRole(userRole);
        setuserSpecialRole(userSpecialRole)
        setUserName(userName)
        setUserID(userID)
        setUserID2(userID2)
    }, []);

    useEffect(() => {
        const authToken = localStorage.getItem('token');
        setIsAuthenticated(!!authToken);
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        handleMenuClose();
    };

    const handleLogoutClick = () => {
        localStorage.clear();


        navigate('/login', { replace: true });
        window.location.href = '/login';
    };

    let destinationRoute = '/dashboard';

    if (userRole === 'admin' || userRole === 'hr' || userRole === 'IT') {
        destinationRoute = '/dashboard';
    } else if (userRole === 'commercial') {
        destinationRoute = '/goodsTable';
    } else if (userRole === 'sender') {
        destinationRoute = '/goodsTableForSender';
    } else if (userRole === 'client') {
        destinationRoute = '/goodsTableForUser';
    } else if (!userRole) {
        destinationRoute = '/register';
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleLogoClick = () => {
        navigate(destinationRoute, { replace: true });
    };




   

    const imageProfile = localStorage.getItem('imageProfile');

   
    const [hrSubMenuAnchorEl, setHrSubMenuAnchorEl] = useState(null);
    const [commercialSubMenuAnchorEl, setCommercialSubMenuAnchorEl] = useState(null);
    const [adminSubMenuAnchorEl, setAdminSubMenuAnchorEl] = useState(null);

   

    const handleHrSubMenuOpen = (event) => {
        setHrSubMenuAnchorEl(event.currentTarget);
    };

    const handleHrSubMenuClose = () => {
       
        setHrSubMenuAnchorEl(null);
    };

    const handleCommercialSubMenuOpen = (event) => {
        setCommercialSubMenuAnchorEl(event.currentTarget);
    };

    const handleCommercialSubMenuClose = () => {
       
        setCommercialSubMenuAnchorEl(null);
    };

    const handleAdminSubMenuOpen = (event) => {
        setAdminSubMenuAnchorEl(event.currentTarget);
    };

    const handleAdminSubMenuClose = () => {
       
        setAdminSubMenuAnchorEl(null);
    };

    // JSX for submenus
    const renderSubMenu = (menuItems, anchorEl, handleClose) => {
        return (
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((menuItem) => (
                    <MenuItem 
                    onClick={ handleMenuClick }

                    key={menuItem.label} component={Link} to={menuItem.path}>
                        {menuItem.icon}
                        {menuItem.label}
                    </MenuItem>
                ))}
            </Menu>
        );
    };


    const handleMenuClick = () => {
        // Close the menu when Home is clicked
        handleMenuClose();
        handleHrSubMenuClose();
        handleCommercialSubMenuClose();
        handleAdminSubMenuClose();
    };

    return (
        <AppBar 

        style={{
            backgroundColor: 'white'
        }}
        
        position="fixed">
            <Toolbar>
                <Typography
                    variant="h6"
                    to={destinationRoute}
                    onClick={handleLogoClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                        textDecoration: 'none',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'transform 0.2s ease-in-out',
                        transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                    }}
                    component={Link}
                >
                    <img src={logoImage} alt="Logo" style={{ marginRight: '8px', height: '55px' }} />
                </Typography>

                <div style={{ flexGrow: 1 }} />
                {isAuthenticated ? (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-controls="user-menu"
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                            >
                                <Avatar alt="User Profile" src={imageProfile} />
                            </IconButton>
                            <Typography variant="caption" style={ { color: 'black' } }>
                                { userName } , ID: { userID === 'undefined' ? userID2 : userID }
                            </Typography>


                        </div>

                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}

                        >
                            <MenuItem 
                            to="/dashboard" component={Link}
                                onClick={ handleMenuClick }
                            
                            >
                                <HomeIcon style={{ color: 'blue', marginRight: '5px' }} />
                                Home
                            </MenuItem>


                            {userRole && (userRole === 'hr' || userRole === 'admin') && (


                                <MenuItem onClick={handleHrSubMenuOpen}>
                                    <ReceiptIcon style={{ color: 'green', marginRight: '5px' }} />
                                    FINANCE / ACCOUNTING
                                </MenuItem>
                            )}




                            {renderSubMenu([
                                { label: 'Depenses', path: '/expensesTable', icon: <ShoppingCartIcon /> },
                                { label: 'Revenus', path: '/RevenuTable', icon: <ReceiptIcon /> },
                                { label: 'Subventions', path: '/SubventionsTable', icon: <CreateIcon /> },
                                
                                { label: 'Paiement Fournisseur', path: '/TablePaiementFournisseur', icon: <ReceiptIcon /> },
                                { label: 'Subventions Rembourse', path: '/TableSubvensionRembourse', icon: <CreateIcon /> },
                                // { label: 'New Depense/Revenue', path: '/expenseForm', icon: <MonetizationOnIcon /> },

                                { label: 'Paiement du support IT', path: '/ITRevenuTable', icon: <MonetizationOnIcon /> },
                            ], hrSubMenuAnchorEl, handleHrSubMenuClose)}


                            { userRole && (userRole === 'commercial' || userRole === 'hr' || userRole === 'admin') && (

                                <MenuItem onClick={handleCommercialSubMenuOpen}>
                                    <AssessmentIcon style={{ color: 'red', marginRight: '5px' }} />
                                    Commercial
                                </MenuItem>
                            )}

                          

                            {renderSubMenu([
                                // { label: 'New Colis', path: '/goodsForm', icon: <LocalShippingIcon /> },
                                { label: 'Track All Goods', path: '/goodsTable', icon: <AssessmentIcon /> },
                                { label: 'Clients', path: '/ClientsPage', icon: <PeopleIcon /> },

                            ], commercialSubMenuAnchorEl, handleCommercialSubMenuClose)}


                            { userRole && (userRole === 'hr' || userRole === 'admin') && (


                                <MenuItem onClick={handleAdminSubMenuOpen}>
                                    <PersonIcon style={{ color: 'black', marginRight: '5px' }} />

                                    Admin
                                </MenuItem>
                            )}

                          

                            {renderSubMenu([
                                { label: 'All Tickets', path: '/userTicketsTable', icon: <CheckCircleIcon /> },
                                { label: 'List of Users', path: '/userTable', icon: <PeopleIcon /> },
                                { label: 'Tâches', path: '/TasksTable', icon: <ListAltIcon /> },

                            ], adminSubMenuAnchorEl, handleAdminSubMenuClose)}


                            <MenuItem to="/userTicketsTableForUsers" component={Link}>
                                <EventNoteIcon style={{ color: 'green', marginRight: '5px' }} />
                                Mes Tickets
                            </MenuItem>

                            <MenuItem to="/GoodsTableForUser" component={ Link }>
                                <LocalShippingIcon style={ { color: 'green', marginRight: '5px' } } />
                                Mes /Colis 📦
                            </MenuItem>


                            <MenuItem to="/adminSettings" component={Link}>
                                <SettingsIcon style={{ color: 'red', marginRight: '5px' }} /> {/* Change the color and icon */}
                                Settings
                            </MenuItem>



                            <MenuItem onClick={handleLogoutClick}>
                                <ExitToAppIcon style={{ color: 'red', marginRight: '5px' }} />
                                Logout
                            </MenuItem>


                        </Menu>
                    </div>
                ) : (
                    <Typography
                        variant="button"
                        to="/login"
                        style={{
                            textDecoration: 'none',
                            color: 'white',
                            padding: '10px 20px',
                            backgroundColor: 'blue',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                        component={Link}
                    >
                        Login
                    </Typography>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
